import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./style.module.scss"

import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"

const ContactForm = () => {
  const {
    sanityContactUsPage: { formInfo: data },
  } = useStaticQuery(graphql`
    query ContactFormInfoQuery {
      sanityContactUsPage {
        formInfo {
          title
          formID
        }
      }
    }
  `)

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "4096483",
    formId: data.formID,
    target: "#my-hubspot-form",
    width: "100%",
  })

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.formBox}>
          <h2 className={styles.formTitle}>{data.title}</h2>
          <div className={styles.theForm}>
            <div id="my-hubspot-form"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
