// extracted by mini-css-extract-plugin
export var buttons = "style-module--buttons--8Uawb";
export var container = "style-module--container--o1HRr";
export var isStyleH1 = "style-module--is-style-h1--4o7N9";
export var isStyleH2 = "style-module--is-style-h2--A1pvu";
export var isStyleH3 = "style-module--is-style-h3--TD-2s";
export var isStyleH4 = "style-module--is-style-h4--jLSyd";
export var isStyleH5 = "style-module--is-style-h5--8xygr";
export var isStyleH6 = "style-module--is-style-h6--O-RWn";
export var section = "style-module--section--NneKQ";
export var title = "style-module--title--w+HAM";