import React, { useState } from "react"
import ContactForm from "../contactForm"
import ContactSupport from "../contactSupport"
import { Link } from "gatsby"

import "./style.sass"

const ContactContent = () => {
  const [showForm, setShowForm] = useState(true)

  return (
    <div id="contact_content">
      <div className="container">
        <div className="buttons">
          <Link to="/contact/" className="showForm active">
            Sales
          </Link>
          <Link to="/support/" className="showForm">
            Support
          </Link>
        </div>
      </div>

      <ContactForm />
    </div>
  )
}

export default ContactContent
