import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./style.module.scss"
import MyLink from "../../myLink"

const ContactSupportCards = () => {
  const {
    sanityContactUsPage: {
      supportCards: { cards: data },
    },
  } = useStaticQuery(graphql`
    query ContactSupportCardsQuery {
      sanityContactUsPage {
        supportCards {
          cards {
            _key
            title
            description
            link {
              url
              newwindow
              linktext
              internallink
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {data.map(card => (
          <div key={card._key} className={styles.card}>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
            <MyLink {...card.link} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContactSupportCards
