// extracted by mini-css-extract-plugin
export var buttons = "style-module--buttons--FX6ga";
export var container = "style-module--container--T8AqN";
export var isStyleH1 = "style-module--is-style-h1--YY7q2";
export var isStyleH2 = "style-module--is-style-h2--DrbHQ";
export var isStyleH3 = "style-module--is-style-h3--AlRUr";
export var isStyleH4 = "style-module--is-style-h4--sh5fd";
export var isStyleH5 = "style-module--is-style-h5--lM-8X";
export var isStyleH6 = "style-module--is-style-h6--IDOX8";
export var section = "style-module--section--OsU3g";
export var title = "style-module--title--of6im";